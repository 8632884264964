<template>
  <div>
    <vs-popup class="sm:popup-w-80" title="Edit Cash Advance" :active="isActive" v-on:update:active="emitModalIsActive">
      <div :class="{'opacity-50 pointer-events-none': isLoadingGetInitData}">
        <div class="vx-row mb-3">
          <div class="vx-col w-full">
            <ValidationErrors :errors="errors"/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-3/12 w-full">
            <label class="ml-1 text-xs">Nomor</label>
            <vs-input class="w-full" v-model="data.no_cash_advance" placeholder="Generate Otomatis" disabled/>
          </div>
          <div class="vx-col sm:w-3/12 w-full">
            <label class="ml-1 text-xs">Tgl. Pengajuan *</label>
            <flat-pickr class="w-full" v-model="data.tgl_pengajuan"></flat-pickr>
          </div>
          <div class="vx-col sm:w-3/12 w-full">
            <label class="ml-1 text-xs">Staf *</label>
            <div @click="modalStaf.active = true">
              <vx-input-group>
                <vs-input :value="data.nama_staf" readonly/>
                <template slot="append">
                  <div class="append-text btn-addon">
                    <vs-button type="filled" icon-pack="feather" icon="icon-search"/>
                  </div>
                </template>
              </vx-input-group>
            </div>
          </div>
          <div class="vx-col sm:w-3/12 w-full">
            <label class="ml-1 text-xs">Proyek *</label>
            <v-select :options="proyeks" label="nama" :reduce="item => item.id" :clearable="false" v-model="data.id_proyek"/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-3/12 w-full">
            <label class="ml-1 text-xs">Kategori *</label>
            <v-select :options="categories" :clearable="false" v-model="data.kategori"/>
          </div>
          <div class="vx-col sm:w-3/12 w-full">
            <label class="ml-1 text-xs">Cara Bayar *</label>
            <v-select :options="caraBayars" label="nama" :reduce="item => item.id" :clearable="false" v-model="data.id_cara_bayar"/>
          </div>
          <div class="vx-col sm:w-6/12 w-full">
            <label class="ml-1 text-xs">Judul *</label>
            <vs-input class="w-full" v-model="data.judul"/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-12/12 w-full">
            <label class="ml-1 text-xs">Keterangan *</label>
            <vs-input class="w-full" v-model="data.keterangan"/>
          </div>
        </div>

        <div v-if="data.kategori === 'BUSINESS TRAVEL'">
          <vs-divider class="mt-base">Detail DLK</vs-divider>
          <div class="vx-row mb-3">
            <div class="vx-col sm:w-3/12 w-full">
              <label class="ml-1 text-xs">Tgl. Mulai *</label>
              <flat-pickr class="w-full" v-model="data.tgl_mulai"></flat-pickr>
            </div>
            <div class="vx-col sm:w-3/12 w-full">
              <label class="ml-1 text-xs">Tgl. Selesai *</label>
              <flat-pickr class="w-full" v-model="data.tgl_selesai"></flat-pickr>
            </div>
            <div class="vx-col sm:w-3/12 w-full">
              <label class="ml-1 text-xs">Tempat Tujuan *</label>
              <vs-input class="w-full" v-model="data.tempat_tujuan"/>
            </div>
            <div class="vx-col sm:w-3/12 w-full">
              <label class="ml-1 text-xs">Tujuan Dinas *</label>
              <vs-input class="w-full" v-model="data.tujuan_dinas"/>
            </div>
          </div>
        </div>

        <vs-divider class="mt-base">Estimasi Pengeluaran</vs-divider>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-6/12 w-full">
            <div class="flex flex-wrap justify-between items-center mb-1">
              <div class="mb-4 md:mb-0 mr-4">
                <div class="flex">
                  <vs-button color="success" icon-pack="feather" icon="icon-plus" @click="addRow" class="py-2 px-3 mr-2">Add</vs-button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col w-full">
            <vs-table :data="data.details" stripe>
              <template slot="thead">
                <vs-th class="whitespace-no-wrap">#</vs-th>
                <vs-th class="whitespace-no-wrap">Nama Item *</vs-th>
                <vs-th class="whitespace-no-wrap">Satuan</vs-th>
                <vs-th class="whitespace-no-wrap">Nominal *</vs-th>
                <vs-th class="whitespace-no-wrap">Files *</vs-th>
              </template>
              <template slot-scope="{data}">
                <vs-tr v-for="(item, index) in data" :key="index" class="text-sm">
                  <vs-td><vs-button class="py-2 px-3" type="filled" color="danger"  icon-pack="feather" icon="icon-x" size="small" @click="removeRow(item.uuid)"/></vs-td>
                  <vs-td>
                    <div @click="chooseItemPengadaan(item.uuid)">
                      <vx-input-group>
                        <vs-input :value="item.nama_item_pengadaan" readonly/>
                        <template slot="append">
                          <div class="append-text btn-addon">
                            <vs-button type="filled" icon-pack="feather" icon="icon-search"/>
                          </div>
                        </template>
                      </vx-input-group>
                    </div>
                  </vs-td>
                  <vs-td><vs-input class="w-full" type="text" :value="item.satuan_item_pengadaan || '-'" readonly/></vs-td>
                  <vs-td><v-money class="w-full" type="text" v-model="item.nominal" placeholder="Rp"/></vs-td>
                  <vs-td>
                    <div class="flex items-center space-x-2">
                      <vs-input class="w-full" type="file" accept=".jpeg,.jpg,.png,.pdf" v-model="item.filesTmp" @change="item.files = $event.target.files"/>
                    </div>
                  </vs-td>
                </vs-tr>
                <!--footer-->
                <vs-tr v-if="data.length > 0">
                  <vs-td class="font-bold text-right"></vs-td>
                  <vs-td class="font-bold text-right"></vs-td>
                  <vs-td class="font-bold text-right">GRAND TOTAL:</vs-td>
                  <vs-td class="font-bold">{{ grandTotal | idr }}</vs-td>
                  <vs-td class="font-bold"></vs-td>
                </vs-tr>
              </template>
            </vs-table>
          </div>
        </div>
        <div class="vx-row mt-4">
          <div class="vx-col w-full">
            <div class="flex justify-end">
              <vs-button class="mr-3" type="border" @click="emitModalIsActive(false)">Batal</vs-button>
              <vs-button :disabled="isLoading" @click="save">
                <span v-if="isLoading" class="animate-pulse">Menyimpan...</span>
                <span v-if="!isLoading">Simpan</span>
              </vs-button>
            </div>
          </div>
        </div>
      </div>

      <!--modals-->
      <vs-popup class="sm:popup-w-70 popup-content-no-padding"
                title="Pilih Data Staf"
                :active.sync="modalStaf.active">
        <Staf :selectable="true"
              :externalFilter="filterStaf"
              @selected="onSelectedModalStaf"/>
      </vs-popup>

      <vs-popup class="sm:popup-w-70 popup-content-no-padding animate-none"
                title="Pilih Item Pengadaan"
                :active.sync="modalItemPengadaan.active">
        <ItemPengadaan :selectable="true"
                       :externalFilter="filterItemPengadaan"
                       @selected="onSelectedModalItemPengadaan"/>
      </vs-popup>

    </vs-popup>
  </div>
</template>

<script>
import CashAdvanceRepository from '@/repositories/procurement/cash-advance-repository'
import ProyekRepository from '@/repositories/master/proyek-repository'
import CaraBayarRepository from '@/repositories/master/cara-bayar-repository'
import ValidationErrors from '@/views/components/validation-errors/ValidationErrors'
import 'flatpickr/dist/flatpickr.css'
import flatPickr from 'vue-flatpickr-component'
import VMoney from '@/views/components/v-money/VMoney'
import vSelect from 'vue-select'
import _ from 'lodash'
import { v4 as uuid } from 'uuid'
import { convertToFormData } from '@/utilities/common/global-methods'

export default {
  name: 'CashAdvanceEdit',
  props: ['isActive', 'idCashAdvance'],
  components: {
    ItemPengadaan: () => import('@/views/pages/master/item-pengadaan/ItemPengadaan'),
    Staf: () => import('@/views/pages/master/staf/Staf'),
    ValidationErrors,
    flatPickr,
    'v-select': vSelect,
    VMoney
  },
  mounted () {
    this.getAllProyek()
    this.getAllCaraBayar()
  },
  watch: {
    isActive (active) {
      if (active) {
        this.getInitData()
      }
    }
  },
  data () {
    return {
      isLoading: false,
      isLoadingGetInitData: false,
      errors: null,
      modalItemPengadaan: {
        rowUuid: null,
        active: false
      },
      modalStaf: {
        active: false
      },
      data: {
        details: [],
        id_proyek: null,
        id_cara_bayar: null
      },
      proyeks: [],
      caraBayars: [],
      deletedIds: [],
      categories: ['BUSINESS TRAVEL', 'EDUCATION', 'ELECTRICAL & UTILITIES', 'HEALTH INSURANCE', 'OFFICE', 'PROFESSIONAL SERVICES', 'SALES & MARKETING', 'REPAIR & MAINTENANCE', 'OTHERS']
    }
  },
  computed: {
    grandTotal () {
      return _.sumBy(this.data.details, item => parseFloat(item.nominal || '0'))
    },
    filterItemPengadaan () {
      return { id_proyek: this.data.id_proyek }
    },
    filterStaf () {
      return { id_proyek: this.data.id_proyek }
    }
  },
  methods: {
    getInitData () {
      this.isLoadingGetInitData = true

      CashAdvanceRepository.show(this.idCashAdvance)
        .then(response => {
          this.data = response.data.data
          this.data.details = _.map(this.data.details, item => {
            item.uuid = uuid()
            item.bukti_nota = null
            return item
          })
        })
        .catch(error => {
          console.log(error)
          if (error.response.status === 422) {
            this.errors = error.response.data.errors
          } else {
            this.notifyError('Terjadi kesalahan.')
          }
        })
        .finally(() => {
          this.isLoadingGetInitData = false
        })
    },

    getAllProyek () {
      const params = {
        filter: JSON.stringify({
          only_authorized_user: 1
        })
      }
      ProyekRepository.getAll(params)
        .then(response => {
          this.proyeks = response.data.data
        })
        .catch(error => {
          console.log(error)
        })
    },

    getAllCaraBayar () {
      const group1 = 'Cash Advance'
      CaraBayarRepository.getAllByGroup(group1)
        .then(response => {
          this.caraBayars = response.data.data
        })
        .catch(error => {
          console.log(error)
        })
    },

    save () {
      this.errors = null
      this.isLoading = true

      const params = convertToFormData({
        ...this.data,
        deletedIds: this.deletedIds
      })
      CashAdvanceRepository.update(params, this.data.id)
        .then(response => {
          this.onSuccess()
        })
        .catch(error => {
          console.log(error)
          if (error.response.status === 422) {
            this.errors = error.response.data.errors
          } else {
            this.notifyError('Terjadi kesalahan.')
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    chooseItemPengadaan (rowUuid) {
      this.modalItemPengadaan.rowUuid = rowUuid
      this.modalItemPengadaan.active = true
    },

    onSelectedModalStaf (staf) {
      this.data.id_staf = staf.id
      this.data.nama_staf = staf.nama
      this.modalStaf.active = false
    },

    onSelectedModalItemPengadaan (data) {
      // validate for same item
      if (_.find(this.data.details, item => item.id_item_pengadaan === data.id) !== undefined) {
        return this.notifyWarning('Item yang sama tidak dapat ditambahkan lagi.')
      }
      const uuid = this.modalItemPengadaan.rowUuid
      const index = _.findIndex(this.data.details, item => item.uuid === uuid)
      this.data.details[index].id_item_pengadaan = data.id
      this.data.details[index].nama_item_pengadaan = data.nama
      this.data.details[index].satuan_item_pengadaan = data.satuan
      this.modalItemPengadaan.active = false
    },

    addRow () {
      const row = {
        uuid: uuid(),
        fileTmp: ''
      }
      this.data.details.push(row)
    },

    removeRow (uuid) {
      const index = _.findIndex(this.data.details, item => item.uuid === uuid)
      const item = this.data.details[index]
      if (item.id) {
        this.deletedIds.push(item.id)
      }
      this.data.details.splice(index, 1)
    },

    onSuccess () {
      this.notifySuccess('Data berhasil disimpan.')
      this.resetData()
      this.emitIsSuccess(true)
      this.emitModalIsActive(false)
    },

    resetData () {
      const exceptObjects = _.pick(this.$data, ['proyeks', 'caraBayars'])
      const newData = Object.assign(this.$options.data.call(this), exceptObjects)
      Object.assign(this.$data, newData)
    },

    emitIsSuccess (isSuccess) {
      this.$emit('success', isSuccess)
    },

    emitModalIsActive (isActive) {
      if (!isActive) {
        this.resetData()
      }
      this.$emit('update:isActive', isActive)
    }
  }
}
</script>
